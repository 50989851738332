import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


// Helpers
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
    status: 'idle',
    isLoading: false,
    isSuccess: false,
    isError: false,
    bookings: [],
    invoices: [],
};

export const getAllBookings = createAsyncThunk('get_all_bookings', async (data) => {
    try{

    let response = await ApiHelperFunction({
        urlPath: '/merchant/view-booking-details',
        method: 'POST',
        data
    });

    if (response.status === 200) {
        return response.data;
    } else {
    }
}catch(error){
  }
});
export const getAllInvoices = createAsyncThunk('get_all_invoices', async (id) => {

    let response = await ApiHelperFunction({
        urlPath: `/merchant/viewMerchantInvoice/${id}`,
        method: 'GET',
        // data
    });

    if (response.status === 200) {
        return response.data;
    } else {
    }
});

export const BookingSlice = createSlice({
    name: 'bookings_actions',
    initialState,
    reducers: {
        clearBookingState: state => {
            state.status = 'idle';
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.bookings = [];
            state.invoices = [];
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAllBookings.pending, state => {
                state.status = 'loading';
                state.isLoading = true;
            })
            .addCase(getAllBookings.fulfilled, (state, { payload }) => {
                state.status = 'success';
                state.isSuccess = true;
                state.isLoading = false;
                state.bookings = payload?.data;
            })
            .addCase(getAllBookings.rejected, state => {
                state.status = 'failed';
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
            })
            .addCase(getAllInvoices.pending, state => {
                state.status = 'loading';
                state.isLoading = true;
            })
            .addCase(getAllInvoices.fulfilled, (state, { payload }) => {
                state.status = 'success';
                state.isSuccess = true;
                state.isLoading = false;
                state.invoices = payload?.data;
            })
            .addCase(getAllInvoices.rejected, state => {
                state.status = 'failed';
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
            });
    },
});

export const { clearBookingState } = BookingSlice.actions;

export default BookingSlice.reducer;
