import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


// Helpers
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
  status: 'idle',
  contactQuestions: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getContactQuestions = createAsyncThunk('get_contact_questions', async () => {
  try{
  let response = await ApiHelperFunction({
    urlPath: '/merchant/viewContactQuestion',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data;
  } else {
  }
}catch(error){
}
});

export const contactQuestionsSlice = createSlice({
  name: 'contact_actions',
  initialState,
  reducers: {
    clearState: state => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getContactQuestions.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getContactQuestions.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.contactQuestions = payload?.data;
      })
      .addCase(getContactQuestions.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = contactQuestionsSlice.actions;

export default contactQuestionsSlice.reducer;
