import React, { useRef, useState, useEffect, useCallback } from "react";
import {  useSelector } from "react-redux";
import { toast } from "react-toastify";
import { capitalizeFirstLetter2 } from "../../../utils";
import { fileUpload, requestData } from "../../../services/api/apiHelpers";
import Loader from "../../../Component/Loader";
import useCountries from "../../../Hooks/useCountries";
import { useParams ,useNavigate } from "react-router-dom";
import SelectInput from "../../../Component/SelectInput";
let initVal = {
  cityId: "",
  cityName: "",
  name: "",
};
let createErrors = {
  cityId: "",
  name: "",
};
function AddTourModule() { 
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [isLoading, setisLoading] = useState(false);
  const [tourModule, settourModule] = useState(initVal);
  const [errors, seterrors] = useState(createErrors);
  const [Img, setImg] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const { id } = useParams();
  const { profile_data } = useSelector(
    (state) => state.profileSlice
  );
  const { cities, fetchCities, loading } = useCountries();
  useEffect(() => {
    setisLoading(loading);
  }, [loading]);
  useEffect(() => {
    if (profile_data?.countryId) fetchCities(profile_data?.countryId);
  }, [profile_data?.countryId]);

  const getTourModuleDetails = useCallback(async () => {
    try{
    let result = await requestData(
      `/merchant/tour-module-details/${id}`,
      "GET"
    );
    if (result && result.status) {
      settourModule((prev) => ({
        ...prev,
        cityId: result.data.cityId,
        name: result.data.name,
      }));
      setImg(result.data.image)
    }
  }catch(error){
            }
  }, [id]);
  
  useEffect(() => {
    if (id) getTourModuleDetails();
  }, [id, getTourModuleDetails]);
  const handleChange = (event) => {
    event.preventDefault();
    let input = capitalizeFirstLetter2(event.target.value);
    settourModule({ ...tourModule, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { countryId, cityId, name } = tourModule;

    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });

      return false;
    }
    if (cityId === "") {
      seterrors({ ...errors, cityId: "City is required" });

      return false;
    }
    if (name === "") {
      window.scrollTo(0, 0);
      seterrors({ ...createErrors, name: "Name is required." });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    if (isLoading) return;
    if (imgLoading) return;

    if (handleValidation()) {
      if (Img === "") return toast.error("Pictured is required");
      try {
        setisLoading(true);
        let data = {
          ...tourModule,
          countryId: profile_data?.countryId,
          image: Img,
        };
        let res = await requestData(
          "/merchant/create-tour-module",
          "POST",
          data
        );

        if (res.status) {
          setImg("");
          settourModule(initVal);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          seterrors(createErrors);
          toast.success("Tour module added successfully!"); 
          navigate("/manage-tour-module");
        } else {
          toast.error(res?.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
      setisLoading(false);
    }
  };

  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    if (imgLoading) return;
    const x = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(x));
    const DATA = new FormData();
    DATA.append("image", e.target.files[0]);
    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload("/admin/tourImage", "POST", DATA);
      if (imgUpRes?.status) {
        setImg(imgUpRes?.url);
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title">{id ?"Edit Your Module" : "Add Tour Module"}</div>
            <div className="row" style={{ margin: "0.1rem" }}>
              <SelectInput
                label="City"
                className="col-md-6"
                options={cities}
                labelKey="cityName"
                valueKey="_id"
                value={tourModule.cityId}
                onChange={(val) => {
                  settourModule((prev) => {
                    return {
                      ...prev,
                      cityId: val?._id,
                    };
                  });
                }}
              />
              
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label font-weight-bold">
                    Activity type: (E.g : Walking tours of London's Westend)
                  </label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={tourModule.name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.name && (
                  <span className="errorMessageText text-danger">
                    {errors.name}
                  </span>
                )}
              </div>
              <div className="col-md-6" id="img_input_block">
                <div className="mb-3">
                  <label
                    htmlFor="image"
                    className="form-label font-weight-bold"
                  >
                    Image*
                  </label>
                  <input
                    name="image"
                    id="image"
                    type="file"
                    accept={"image/*"}
                    ref={fileInputRef}
                    className="form-control"
                    onChange={imageHandler}
                  />
                </div>
                <p className="text-primary mt-2">
                  {imgLoading && "Uploading please wait..."}
                </p>
                {Img !== "" && (
                  <>
                    <img
                      alt=""
                      style={{
                        height: "70px",
                        width: "120px",
                        borderRadius: "3px",
                      }}
                      src={Img}
                    ></img>
                  </>
                )}
              </div>
            </div>

            <button
              className="mt-2 btn btn-primary ml-auto d-table"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTourModule;

