import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// Helpers
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
  status: 'idle',
  subadmin_details: {},
  subadmins: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const addSubadmin = createAsyncThunk('admin_add_subadmin', async data => {
  try{
  let response = await ApiHelperFunction({
    urlPath: '/admin/admin-add-subadmin',
    method: 'POST',
    data: data,
  });

  if (response.status === 200) {
    toast.success('Subadmin registered successfully!');
    return response.data;
  } else {
    toast.error('Failed to create subadmin. Something went wrong');
  }
}catch(error){
}
});

export const getAllSubadmins = createAsyncThunk('admin_get_all_subadmins', async () => {
  try{
  let response = await ApiHelperFunction({
    urlPath: '/admin/get-all-member-profile',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
}catch(error){
}
});

export const SubadminActionSlice = createSlice({
  name: 'admin_subadmin_operations',
  initialState,
  reducers: {
    clearState: state => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addSubadmin.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(addSubadmin.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.subadmin_details = payload.data;
      })
      .addCase(addSubadmin.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(getAllSubadmins.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getAllSubadmins.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.subadmins = payload?.data;
      })
      .addCase(getAllSubadmins.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = SubadminActionSlice.actions;

export default SubadminActionSlice.reducer;
