import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {  NavLink } from "react-router-dom";
import { changeflag } from "../../redux/merchantSlice/ControlTableSlice";

export default function Sidebar() {
  const INITIAL_CHECKBOX = {
    dashboard: true,
    account: true,
    createUser: true,
    accountDetails: true,
    accountManagement: true,
    products: true,
    createProducts: true,
    manageProducts: true,
    pendingProducts: true,
    rejectProducts: true,
    manageDraftProducts: true,
    activityAssignByAdmin: true,
    productAvialAbility: true,
    addProductAvailability: true,
    checkProductAvailability: true,
    cancelProductAvailability: true,
    offers: true,
    addSpecialOffer: true,
    manageSpecialOffer: true,
    bookings: true,
    orderInvoice: true,
    reviewAndRating: true,
    help: true,
    faqs: true,
    contactForm: true,
    finance: true,
    bookingForPayout: true,
    invoices: true,
    paymentDetails: true,
    paymentSettings: true,
  };


  const [tabPermission, setTabpermission] = useState([]);

  const dispatch = useDispatch();
  const ProfileType = useSelector((state) => state.profileSlice);

  useEffect(() => {
    const isSubmerchant = ProfileType?.profile_data?.subMrchent;

    if (isSubmerchant === true) {
      setTabpermission(ProfileType?.profile_data?.tabPermission);
    } else {
      setTabpermission([INITIAL_CHECKBOX]);
    }
  }, [ProfileType,]);

  return (
    <>
      <div className="sidebar-mobile-overlay"></div>
      <div
        className="app-sidebar bg-royal sidebar-text-light sidebar-shadow appear-done enter-done fade-in slide-right"
        id="sidebar"
        style={{ height: "95%", overflow: "scroll", paddingTop: "0" }}
      >
        <div className="app-sidebar__inner">
          {/* Dashboard */}
          {tabPermission?.[0]?.dashboard && (
            <div className="metismenu vertical-nav-menu">
              <ul className="metismenu-container">
                <li className="metismenu-item" style={{ fontWeight: "bold" }}>
                  <NavLink className="metismenu-link" to="/">
                    <i className="metismenu-icon fa-solid fa-gauge"></i>
                    Dashboard
                  </NavLink>
                </li>
              </ul>
            </div>
          )}

          {/* Account */}
          {tabPermission?.[0]?.account && (
            <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingOne"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseThree1"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                    style={{ fontWeight: "bold" }}
                  >
                    <i className="metismenu-icon fa-regular fa-building"></i>
                    Account
                  </a>
                </div>
              </div>
              <div
                id="collapseThree1"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                {tabPermission?.[0]?.createUser && (
                  <div className="panel-body">
                    <ul className="metismenu-container">
                      <li className="metismenu-item">
                        <NavLink
                          to="/create-profile"
                          className="metismenu-link"
                        >
                          Create User
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
                {ProfileType?.profile_data?.subMrchent === false &&
                  tabPermission?.[0]?.accountDetails && (
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <NavLink
                            to="/add-company-details"
                            className="metismenu-link"
                          >
                            Account Details
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                {ProfileType?.profile_data?.subMrchent === true &&
                  tabPermission?.[0]?.accountManagement && (
                    <div className="panel-body">
                      <ul className="metismenu-container">
                        <li className="metismenu-item">
                          <NavLink
                            to="/userAccount-Details"
                            className="metismenu-link"
                          >
                            Account Details
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                <div className="panel-body">
                  <ul className="metismenu-container">
                    <li className="metismenu-item">
                      <NavLink
                        to="/account-management"
                        className="metismenu-link"
                      >
                        Account Management
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          <div
            className="panel-group"
            id="accordionMenu"
            role="tablist"
            aria-multiselectable="true"
          >
            {/* Products */}
            {tabPermission?.[0]?.products && (
              <div className="panel panel-default metismenu vertical-nav-menu">
                <div
                  className="panel-heading metismenu-container"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-title metismenu-item">
                    <a
                      className="collapsed metismenu-link"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordionMenu"
                      href="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                      style={{ fontWeight: "bold" }}
                    >
                      <i className="metismenu-icon fa-brands fa-product-hunt"></i>
                      Products
                    </a>
                  </div>
                </div>
                <div
                  id="collapseThree"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="metismenu-container">
                      {tabPermission?.[0]?.createProducts && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/create-products"
                            className="metismenu-link"
                          >
                            Create Products
                          </NavLink>
                        </li>
                      )}
                      {tabPermission?.[0]?.manageProducts && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/manage-products"
                            className="metismenu-link"
                          >
                            Manage Products
                          </NavLink>
                        </li>
                      )}
                      {tabPermission?.[0]?.pendingProducts && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/pending-product"
                            className="metismenu-link"
                          >
                            Pending Products
                          </NavLink>
                        </li>
                      )}
                      {tabPermission?.[0]?.rejectProducts && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/reject-product"
                            className="metismenu-link"
                          >
                            Reject Products
                          </NavLink>
                        </li>
                      )}
                      {tabPermission?.[0]?.manageDraftProducts && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/manage-drafts"
                            className="metismenu-link"
                          >
                            Manage Draft Products
                          </NavLink>
                        </li>
                      )}
                      {tabPermission?.[0]?.activityAssignByAdmin && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/activityassignbyadmin"
                            className="metismenu-link"
                          >
                            Activity Assign By Admin
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div
                  id="collapseThree"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="metismenu-container">
                      <li className="metismenu-item">
                        <NavLink
                          to="/activityassignbyadmin"
                          className="metismenu-link"
                        >
                          Activity Assign By Admin
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {/* Products Availibility */}
            {/* {tabPermission?.[0]?.productAvialAbility && (
              <div className="panel panel-default metismenu vertical-nav-menu">
                <div
                  className="panel-heading metismenu-container"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-title metismenu-item">
                    <a
                      className="collapsed metismenu-link"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordionMenu"
                      href="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                      style={{ fontWeight: "bold" }}
                    >
                      <i className="metismenu-icon fa-brands fa-product-hunt"></i>
                      Products Availability
                    </a>
                  </div>
                </div>
                <div
                  id="collapseFour"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="metismenu-container">
                      {tabPermission?.[0]?.addProductAvailability && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/add-product-availability"
                            className="metismenu-link"
                          >
                            Add Product Availability
                          </NavLink>
                        </li>
                      )}
                      {tabPermission?.[0].checkProductAvailability && (
                        <li
                          className="metismenu-item"
                          onClick={() => {
                            dispatch(changeflag(false));
                          }}
                        >
                          <NavLink
                            to="/check-product-availability"
                            className="metismenu-link"
                          >
                            Check Product Availability
                          </NavLink>
                        </li>
                      )}
                      {tabPermission?.[0]?.cancelProductAvailability && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/cancel-product-availability"
                            className="metismenu-link"
                          >
                            Cancel Product Availability
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )} */}

              {/* Additional Stuff*/}
             
              <div className="metismenu vertical-nav-menu ">
                <ul className="metismenu-container">
                  <li className="metismenu-item" style={{ fontWeight: "bold" }}>
                    <NavLink className="metismenu-link" to="/additional-stuff">
                      
                      <i className="metismenu-icon fas fa-person-cane"></i>
                     Additional Stuff
                    </NavLink>
                  </li>
                </ul>
              </div>



             {tabPermission?.[0]?.offers &&(
              <div className="panel panel-default metismenu vertical-nav-menu">
              <div
                className="panel-heading metismenu-container"
                role="tab"
                id="headingTwentyFour"
              >
                <div className="panel-title metismenu-item">
                  <a
                    className="collapsed metismenu-link"
                    role="button"
                    data-toggle="collapse"
                    data-parent="#accordionMenu"
                    href="#collapseTwentyFour"
                    aria-expanded="true"
                    aria-controls="collapseTwentyFour"
                    style={{ fontWeight: "bold" }}
                  >
                    <i className="metismenu-icon fa fa-briefcase"></i>
                    Tour Modules
                  </a>
                </div>
              </div>
              <div
                id="collapseTwentyFour"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwentyFour"
              >
                <div className="panel-body">
                  <ul className="metismenu-container">
                  {/* {(userType == "admin" || (userType == "subadmin" && assignAccess.includes('create'))) && ( */}
                      <li className="metismenu-item" >
                        <NavLink to="/add-tour-module" className="metismenu-link" >
                          Add Tour Module
                        </NavLink>
                      </li>
                  {/* // )} */}
                    <li className="metismenu-item" >
                      <NavLink
                        to="/manage-tour-module"
                        className="metismenu-link"
                      >
                        Manage Tour Module
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
             )}
            
            {tabPermission?.[0]?.offers && (
              <div className="panel panel-default metismenu vertical-nav-menu">
                <div
                  className="panel-heading metismenu-container"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-title metismenu-item">
                    <a
                      className="collapsed metismenu-link"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordionMenu"
                      href="#collapseFive"
                      aria-expanded="true"
                      aria-controls="collapseFive"
                      style={{ fontWeight: "bold" }}
                    >
                      {/* <i className="metismenu-icon fa-brands fa-product-hunt"></i> */}
                      <i className="metismenu-icon fa fa-gift"></i>
                      Offers
                    </a>
                  </div>
                </div>
                <div
                  id="collapseFive"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="metismenu-container">
                      {tabPermission?.[0]?.addSpecialOffer && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/add-special-offer"
                            className="metismenu-link"
                          >
                            Add Special Offer
                          </NavLink>
                        </li>
                      )}
                      {tabPermission?.[0]?.manageSpecialOffer && (
                        <li
                          className="metismenu-item"
                          onClick={() => {
                            dispatch(changeflag(true));
                          }}
                        >
                          <NavLink
                            to="/manage-special-offers"
                            className="metismenu-link"
                          >
                            Manage Special Offer
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {/* Bookings */}
            {tabPermission?.[0]?.bookings && (
              <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container">
                  <li className="metismenu-item" style={{ fontWeight: "bold" }}>
                    <NavLink className="metismenu-link" to="/view-bookings">
                      <i className="metismenu-icon fa-solid fa-bag-shopping"></i>
                      Bookings
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}

            {/* Order Invoice */}
            {tabPermission?.[0]?.orderInvoice && (
              <div className="metismenu vertical-nav-menu ">
                <ul className="metismenu-container">
                  <li className="metismenu-item" style={{ fontWeight: "bold" }}>
                    <NavLink className="metismenu-link" to="/view-bookingInvoice">
                      
                      <i className="metismenu-icon fas fa-file-invoice"></i>
                      Order Invoice
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}

            {/* Agenda */}
            {/* <div className="metismenu vertical-nav-menu">
              <ul className="metismenu-container">
                <li className="metismenu-item" style={{ fontWeight: "bold" }}>
                  <NavLink className="metismenu-link" to="/coming-soon">
                    <i className="metismenu-icon fa-solid fa-calendar-day"></i>
                    Agenda
                  </NavLink>
                </li>
              </ul>
            </div> */}
            {/* Performance */}
            {/* <div className="metismenu vertical-nav-menu">
              <ul className="metismenu-container">
                <li className="metismenu-item" style={{ fontWeight: "bold" }}>
                  <NavLink className="metismenu-link" to="/coming-soon">
                    <i className="metismenu-icon fa-solid fa-gauge-simple-high"></i>
                    Performance
                  </NavLink>
                </li>
              </ul>
            </div> */}

            {/* Reviews */}
            {tabPermission?.[0]?.reviewAndRating && (
              <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container">
                  <li className="metismenu-item" style={{ fontWeight: "bold" }}>
                    <NavLink className="metismenu-link" to="/reviewHome">
                      <i className="metismenu-icon fa-solid fa-pencil"></i>
                      Review and Rating
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}

            {/* Help */}
            {tabPermission?.[0]?.help && (
              <div className="panel panel-default metismenu vertical-nav-menu">
                <div
                  className="panel-heading metismenu-container"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-title metismenu-item">
                    <a
                      className="collapsed metismenu-link"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordionMenu"
                      href="#collapseThree22"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                      style={{ fontWeight: "bold" }}
                    >
                      <i className="metismenu-icon fa-regular fa-circle-question"></i>
                      Help
                    </a>
                  </div>
                </div>
                <div
                  id="collapseThree22"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="metismenu-container">
                      {tabPermission?.[0]?.faqs && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/frequently-asked-questions"
                            className="metismenu-link"
                          >
                            FAQs
                          </NavLink>
                        </li>
                      )}
                      {/* {tabPermission?.[0]?.contactForm && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/contact-form"
                            className="metismenu-link"
                          >
                            Contact Form
                          </NavLink>
                        </li>
                      )} */}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {/* Finance */}
            {tabPermission?.[0]?.finance && (
              <div className="panel panel-default metismenu vertical-nav-menu">
                <div
                  className="panel-heading metismenu-container"
                  role="tab"
                  id="headingOne"
                >
                  <div className="panel-title metismenu-item">
                    <a
                      className="collapsed metismenu-link"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordionMenu"
                      href="#collapseThree29"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                      style={{ fontWeight: "bold" }}
                    >
                      <i className="metismenu-icon fa-regular fas fa-balance-scale"></i>
                      Finance
                    </a>
                  </div>
                </div>
                <div
                  id="collapseThree29"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="metismenu-container">
                      {tabPermission?.[0]?.bookingForPayout && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/booking-payout"
                            className="metismenu-link"
                          >
                            Booking-Payout
                          </NavLink>
                        </li>
                      )}

                      {tabPermission?.[0]?.invoices && (
                        <li className="metismenu-item">
                          <NavLink to="/invoice" className="metismenu-link">
                            Invoices
                          </NavLink>
                        </li>
                      )}

                      {/* {tabPermission?.[0]?.paymentSettings && (
                        <li className="metismenu-item">
                          <NavLink
                            to="/payment-settings"
                            className="metismenu-link"
                          >
                            PaymentSetting
                          </NavLink>
                        </li>
                      )} */}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
