import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    isModalOpen: false, // Flag to track modal state

};


export const modalSlice = createSlice({
    name: 'modal_actions',
    initialState,
    reducers: {
        openModal: state => {
            state.isModalOpen = !state.isModalOpen; // Set isModalOpen to true when the modal is opened
        },
        closeModal: state => {
            state.isModalOpen = false; // Set isModalOpen to false when the modal is closed
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
