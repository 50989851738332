// Hooks
import React, {  useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Redux actions
import { getAllBookings } from "../../../redux/merchantSlice/bookingSlice";

// API functions
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";



function BookingDetailedView() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookings } = useSelector((state) => state.bookingSlice);
  const detailData = bookings?.find((item) => item?._id === params.id);
  useEffect(() => {
    dispatch(getAllBookings);
  }, [bookings]);
  const cancelBooking = async (id) => {
    try {
      let res = await JSON_API["cancelBooking"]({ id });

      if (res.isSuccess) {
        dispatch(getAllBookings());
        toast.success("Booking successfully cancelled.");
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
    }
  };
  return (
    <>
      <div className="info-details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex">
              <div className="obser-top">
                <p>{`Booking Details`}</p>
              </div>
              <div className="obser-top" style={{ marginLeft: "auto" }}>
                <button
                  className="btn btn-secondary btn-lg"
                  onClick={() => navigate("/view-bookings")}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div className="des-detail">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Activity Name</span>
                  <h6>
                    {detailData?.activity[0]?.activityTitle
                      ?.charAt(0)
                      ?.toUpperCase() +
                      detailData?.activity[0]?.activityTitle.slice(1)}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Booking Date</span>
                  <h6>{detailData?.bookingDate}</h6>
                </div>
                <div className="DetailsData">
                  <span>Participants</span>
                  <h6>
                    {detailData?.participentType?.map((item, index) => {
                      if (item?.person !== 0)
                        return (
                          item?.pertype?.charAt(0)?.toUpperCase() +
                          item?.pertype?.slice(1)?.toLowerCase() +
                          ", "
                        );
                    })}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Payment Mode</span>
                  <h6 className="">{detailData?.paymentMode}</h6>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Activity Owner</span>
                  <h6>{detailData?.activity[0]?.addedBY}</h6>
                </div>
                <div className="DetailsData">
                  <span>Booking Time</span>
                  <h6>{detailData?.bookingTime}</h6>
                </div>
                <div className="DetailsData">
                  <span>Booking Amount</span>
                  <h6>{detailData?.amount}</h6>
                </div>
                <div className="DetailsData">
                  <span>Total Number of Persons</span>
                  <div>
                    {" "}
                    <h6>{detailData?.totalTourPerson}</h6>
                  </div>
                </div>
                <div className="DetailsData">
                  <span>Booking Reference ID</span>
                  <div>
                    {" "}
                    <h6>{detailData?.alpfaNueID}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="col-md-6 col-6">
              <div className="column">
                <div
                  className="employeeDetails"
                  style={{ padding: 20, display: "flex" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 d-flex">
              <div className="obser-top">
                <p>{`User Details` /**${detailData?.merchantName} :*/}</p>
              </div>
            </div>
          </div>

          <div className="">
            <div className="des-detail">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>User Name</span>
                  <h6>
                    {detailData?.userDetails?.firstName
                      .charAt(0)
                      ?.toUpperCase() +
                      detailData?.userDetails?.firstName?.slice(1) +
                      " " +
                      detailData?.userDetails?.lastName
                        .charAt(0)
                        ?.toUpperCase() +
                      detailData?.userDetails?.lastName?.slice(1)}
                  </h6>
                </div>
                <div className="DetailsData">
                  <span>Email</span>
                  <h6>{detailData?.userDetails?.email}</h6>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="DetailsData">
                  <span>Mobile</span>
                  <h6>{detailData?.userDetails?.mobileNo}</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className="col-md-6 col-6">
              <div className="column">
                <div
                  className="employeeDetails"
                  style={{ padding: 20, display: "flex" }}
                >
                  <div className="DetailsData mr-5">
                    <span>Activity Image</span>
                    <div
                      className="d-flex task_img"
                      style={{ width: "55rem", overflowX: "auto" }}
                    >
                      {detailData?.activity[0]?.image?.map((item, idx) => (
                        <img
                          key={idx}
                          src={item}
                          className="img-fluid mr-5"
                          alt=""
                          style={{ height: "6rem", width: "9rem" }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="mb-5"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              disabled={
                detailData?.bookingStatus === "cancelled" ? true : false
              }
              className={`mt-2 btn d-table ${
                detailData?.bookingStatus === "confirmed"
                  ? "btn-primary"
                  : "btn-secondary"
              }`}
              onClick={() => cancelBooking(detailData?._id)}
              style={{
                width: "90px",
                backgroundColor:
                detailData?.bookingStatus === "confirmed" ? "green" : "red",
              }}
            >
              {detailData?.bookingStatus === "confirmed"
                ? "Cancel"
                : "CANCELLED"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingDetailedView;
