// MainLoader.js
import React from 'react';
import Loader from '../Component/Loader';
import { createPortal } from 'react-dom';

const MainLoader = ({
  isLoading,
  loaderSrc = '/path/to/loader.gif', // Adjust the path to your loader image
  backgroundColor = 'rgba(0, 0, 0, 0.5)',
  userLoaderStyles = {},
  userLoadingTextStyles = {},
}) => {
  return (
    <>
      {createPortal(
        <Loader
          displayProperty={isLoading ? 'flex' : 'none'}
          loaderSrc={loaderSrc}
          backgroundColor={backgroundColor}
          userLoaderStyles={userLoaderStyles}
          userLoadingTextStyles={userLoadingTextStyles}
        />,
        document.getElementById('loaderDiv') // Ensure this element exists in your HTML
      )}
    </>
  );
};

export default MainLoader;
