import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

// Reducers - Admin
import AuthSliceReducer from "./authSlice/AuthSlice";
import AdminSubaminActionReducer from "./adminSlice/subadminActionsSlice";
import LanguageSliceReducer from "./adminSlice/languageSlice";
import AdminMerchangtActionReducer from "./adminSlice/merchantActionsSlice";
import AdminActivitiyActionReducer from "./adminSlice/activitySlice";
import CountrySliceReducer from "./adminSlice/countrySlice";
import BannerSliceReducer from "./adminSlice/bannerSlice";
import SectionSliceReducer from "./adminSlice/sectionSlice";
import categorySliceReducer from "./adminSlice/categorySlice";
import CitySliceReducer from "./adminSlice/citySlice";
import AboutusSliceReducer from "./adminSlice/aboutusSlice";
import PressSliceReducer from "./adminSlice/pressSlice";
import BlogsSliceReducer from "./adminSlice/blogSlice";
import CareerSliceReducer from "./adminSlice/careerSlice";
import LegalNoticeSliceReducer from "./adminSlice/legalNoticeSlice";
import TermsAndConSliceReducer from "./adminSlice/termsAndConSlice";
import CookiesSliceReducer from "./adminSlice/cookiesSlice";
import PrivacyPolicySliceReducer from "./adminSlice/privacyPolicySlice";
import ContactsSliceReducer from "./adminSlice/contactsSlice";
import factsSlice from "./adminSlice/factsSlice";
import contactQuestionsSlice from "./adminSlice/contactQuestionsSlice";
import accountSlice from "./adminSlice/accountSlice";

// Reducer - Merchant
import MerchantProfileReducer from "./merchantSlice/profileSlice";
import SpecialOffersSliceReducer from "./merchantSlice/specialOfferSlice";
import bookingSliceReducer from "./merchantSlice/bookingSlice";
import tourModuleSliceReducer from "./merchantSlice/tourModuleSlice";
import participantsSliceReducer from "./merchantSlice/participantsSlice";
import ReviewRatingSliceReducer from "./merchantSlice/reviewRatingSlice";
import ModalReducer from "./merchantSlice/modalSlice"
// import FinanceReducer from "./merchantSlice/Finance";
import YearReducer from "./merchantSlice/yearSlice"
//tablechage
import ControlTableReducer from "./merchantSlice/ControlTableSlice";
// import { changeflag } from './merchantSlice/ControlTableSlice';

let rootReducer = combineReducers({
  authSlice: AuthSliceReducer,
  profileSlice: MerchantProfileReducer,
  adminSubadminActionSlice: AdminSubaminActionReducer,
  languageSlice: LanguageSliceReducer,
  adminMerchantActionSlice: AdminMerchangtActionReducer,
  adminActivityActionSlice: AdminActivitiyActionReducer,
  countrySlice: CountrySliceReducer,
  adminBannerSlice: BannerSliceReducer,
  adminSectionActionsSlice: SectionSliceReducer,
  adminCategoryActionSlice: categorySliceReducer,
  citySlice: CitySliceReducer,
  adminAboutsActionSlice: AboutusSliceReducer,
  pressSlice: PressSliceReducer,
  blogsSlice: BlogsSliceReducer,
  careerSlice: CareerSliceReducer,
  legalNoticeSlice: LegalNoticeSliceReducer,
  termsConditionSlice: TermsAndConSliceReducer,
  cookiesMarketingSlice: CookiesSliceReducer,
  privacyPolicySlice: PrivacyPolicySliceReducer,
  contactsSlice: ContactsSliceReducer,
  specialOfferSlice: SpecialOffersSliceReducer,
  reviewRatingSlice: ReviewRatingSliceReducer,
  factsSlice: factsSlice,
  contactQuestionsSlice: contactQuestionsSlice,
  accountSlice: accountSlice,
  bookingSlice: bookingSliceReducer,
  tourModuleSice: tourModuleSliceReducer,
  participantsSlice: participantsSliceReducer,
  controlTable: ControlTableReducer,
  modalSlice: ModalReducer,
  // FinanceSlice: FinanceReducer
  yearSlice : YearReducer
});


const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
