import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// Helpers
import { ApiHelperFunction } from "../../services/api/apiHelpers";

let initialState = {
  status: "idle",
  abouts: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllAbouts = createAsyncThunk(
  "get_all_about",
  async () => {

    try {

    let response = await ApiHelperFunction({
      urlPath: "/admin/viewAboutUs",
      method: "GET",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error ("Can't get data. Something went wrong");
    }

  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error (error.message);
  }
  }
);

export const AboutsSlice = createSlice({
  name: "abouts_actions",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAbouts.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllAbouts.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.abouts = payload.data;
      })
      .addCase(getAllAbouts.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = AboutsSlice.actions;

export default AboutsSlice.reducer;
