// API url = BASE_URL + API_ENDPOINT_PATH + '/' + <ENDPOINT>
// export const BASE_URL = 'http://127.0.0.1:8025/';
 export const BASE_URL = 'https://api.nowthingstodo.com/';
export const API_ENDPOINT_PATH = 'api/v1';
// API_NOTIFICATION_MESSAGES
export const API_NOTIFICATION_MESSAGES = {
  loading: {
    title: 'Loading...',
    message: 'Data is being loaded. Please wait',
  },
  success: {
    title: 'Success',
    message: 'Data successfully loaded',
  },
  responseFailure: {
    title: 'Error',
    message: 'An error occured while fetching response from server. Please try again.',
  },
  requestFailue: {
    title: 'Error',
    message: 'An error occurred while parsing request data.',
  },
  networkError: {
    title: 'Error',
    message: 'Unable to connect with server. Please check internet connectivity and try again.',
  },
};

// API SERVICE CALL
// SAMPLE REQUEST
// NEED SERVICE CALL: {url: '/', method: 'POST/GET/PUT/DELETE', params: true/false, query: true/false, responseType}
export const SERVICE_URLS = {
  adminSignup: { url: '/admin/register', method: 'POST' },
  adminLogin: { url: '/admin/login', method: 'POST' },
  adminAddSubadmin: { url: '/admin/admin-add-subadmin', method: 'POST' },
  getAllMemberProfiles: { url: '/admin/get-all-member-profile', method: 'GET' },
  editSubadminInfo: {
    url: '/admin/update-member-profile',
    method: 'PUT',
    params: true,
  },
  adminDeleteSubadmin: {
    url: '/admin/deleteSubadminProfile',
    method: 'PUT',
    params: true,
  },
  adminaAddMerchant: { url: '/admin/admin-add-merchant', method: 'POST' },
  adminGetAllMerchants: {
    url: '/admin/get-all-merchant-profile',
    method: 'POST',
  },
  adminEditMerchant: {
    url: '/admin/update-merchant-profile',
    method: 'PUT',
    params: true,
  },
  adminDeleteMerchant: {
    url: '/admin/deleteMarchent',
    method: 'PUT',
    params: true,
  },
  adminAddActivityType: {
    url: '/admin/admin-add-activity-type',
    method: 'POST',
  },
  adminGetAllActivityTypes: {
    url: '/admin/admin-view-activity-type',
    method: 'GET',
  },
  adminEditActivityType: {
    url: '/admin/edit-activity-type',
    method: 'PUT',
    params: true,
  },
  adminDeleteActivityType: {
    url: '/admin/delete-activity-type',
    method: 'PUT',
    params: true,
  },
  adminAddActivitySite: { url: '/admin/addActivitySite', method: 'POST' },
  adminGetAllActivitySites: { url: '/admin/viewActivitySite', method: 'GET' },
  adminEditActivitySite: {
    url: '/admin/editActivitySite',
    method: 'PUT',
    params: true,
  },
  adminDeleteActivitySite: {
    url: '/admin/deleteActivitySite',
    method: 'PUT',
    params: true,
  },
  addCountry: { url: '/admin/add-country', method: 'POST' },
  getAllCountries: { url: '/admin/view-country', method: 'GET' },
  editCountry: { url: '/admin/editCountry', method: 'PUT', params: true },
  deleteCountry: { url: '/admin/deleteCountry', method: 'PUT', params: true },
  adminAddBanner: { url: '/admin/add-banner', method: 'POST' },
  adminGetAllBanners: { url: '/admin/view-banner', method: 'GET' },
  adminEditBanner: { url: '/admin/edit-banner', method: 'PUT', params: true },
  adminDeleteBanner: {
    url: '/admin/delete-banner',
    method: 'PUT',
    params: true,
  },
  adminAddLanguage: { url: '/admin/admin-add-language', method: 'POST' },
  getAllLanguages: { url: '/admin/view-all-language', method: 'GET' },
  changeLangStatus: {
    url: '/admin/change-status',
    method: 'PUT',
    params: true,
  },
  deleteLanguage: { url: '/admin/deleteLanguage', method: 'PUT', params: true },
  adminAddSection: { url: '/admin/add-section', method: 'POST' },
  adminGetAllSection: { url: '/admin/view-section', method: 'GET' },
  adminEditSection: {
    url: '/admin/edit-section',
    method: 'PUT',
    params: true,
  },

  adminAddCategory: { url: '/admin/add-category', method: 'POST' },
  adminGetAllCategories: { url: '/admin/view-category', method: 'GET' },
  adminEditCategory: {
    url: '/admin/edit-category',
    method: 'PUT',
    params: true,
  },
  adminDelteCategory: {
    url: '/admin/delete-category',
    method: 'PUT',
    params: true,
  },

  adminAddAbout: { url: '/admin/addAboutUs', method: 'POST' },
  adminGetAllAbouts: { url: '/admin/viewAboutUs', method: 'GET' },
  adminEditAbouts: {
    url: '/admin/editAbouUs',
    method: 'PUT',
    params: true,
  },
  adminDelteAbout: { url: '/admin/deleteAboutUs', method: 'PUT', params: true },

  adminDeleteSection: {
    url: '/admin/delete-section',
    method: 'PUT',
    params: true,
  },
  adminAddCity: { url: '/admin/addCity', method: 'POST' },
  adminGetAllCities: { url: '/admin/viewCity', method: 'GET' },
  adminEditCity: { url: '/admin/editCity', method: 'PUT', params: true },
  adminDeleteCity: { url: '/admin/deleteCity', method: 'PUT', params: true },
  adminAddPressRelease: { url: '/admin/addPress', method: 'POST' },
  adminGetAllPressReleases: { url: '/admin/viewPress', method: 'GET' },
  adminEditPress: { url: '/admin/editPress', method: 'PUT', params: true },
  adminDeletePress: { url: '/admin/deletePress', method: 'PUT', params: true },
  adminAddBlog: { url: '/admin/addBlog', method: 'POST' },
  adminGetAllBlogs: { url: '/admin/viewBlog', method: 'GET' },
  adminEditBlog: { url: '/admin/editBlog', method: 'PUT', params: true },
  adminDeleteBlog: { url: '/admin/deleteBlog', method: 'PUT', params: true },
  adminAddCareer: { url: '/admin/addCareer', method: 'POST' },
  adminGetAllCareers: { url: '/admin/viewCareer', method: 'GET' },
  adminEditCareer: { url: '/admin/editCareer', method: 'PUT', params: true },
  adminDeleteCareer: {
    url: '/admin/deleteCareer',
    method: 'PUT',
    params: true,
  },
  adminAddLegalNotice: { url: '/admin/addLegalNotice', method: 'POST' },
  adminGetAllLegalNotices: { url: '/admin/viewLegalNotice', method: 'GET' },
  // editLegalNotice: {
  //   url: '/admin/editLegalNotice',
  //   method: 'PUT',
  //   params: true,
  // },

  adminAddTerms: { url: '/admin/addTermsCondition', method: 'POST' },
  adminGetAllTerms: { url: '/admin/viewTermsCondition', method: 'GET' },
  adminEditTerms: {
    url: '/admin/editTermsCondition',
    method: 'PUT',
    params: true,
  },
  adminDeleteTerms: {
    url: '/admin/deleteTermsCondition',
    method: 'PUT',
    params: true,
  },

  adminAddCookiesMarketing: {
    url: '/admin/addCookiesMarketing',
    method: 'POST',
  },
  adminGetAllCookiesMarketing: {
    url: '/admin/viewCookiesMarketing',
    method: 'GET',
  },
  adminEditCookiesMarketing: {
    url: '/admin/editCookiesMarketing',
    method: 'PUT',
    params: true,
  },
  adminDeleteCookiesMarketing: {
    url: '/admin/deleteCookiesMarketing',
    method: 'PUT',
    params: true,
  },
  editLegalNotice: {
    url: '/admin/editLegalNotice',
    method: 'PUT',
    params: true,
  },
  adminDeleteLegalNotice: {
    url: '/admin/deleteLegalNotice',
    method: 'PUT',
    params: true,
  },
  adminAddPrivacyPolicy: { url: '/admin/addPrivacyPolicy', method: 'POST' },
  getAllPrivacyPolicies: { url: '/admin/viewPrivacyPolicy', method: 'GET' },
  editPrivacyPolicy: {
    url: '/admin/editPrivacyPolicy',
    method: 'PUT',
    params: true,
  },
  adminDeletePrivacyPolicy: {
    url: '/admin/deletePrivacyPolicy',
    method: 'PUT',
    params: true,
  },
  adminAddContact: { url: '/admin/addContact', method: 'POST' },
  adminGetAllContacts: { url: '/admin/viewContact', method: 'GET' },
  adminEditContact: { url: '/admin/editContact', method: 'PUT', params: true },
  adminDeleteContact: {
    url: '/admin/deleteContact',
    method: 'PUT',
    params: true,
  },
  adminAddSpecialOffer: { url: '/admin/createSpecialOffer', method: 'POST' },
  adminGetAllSpecialOffers: { url: '/admin/viewSpecialOffer', method: 'GET' },
  adminEditSpecialOffer: { url: '/merchant/editSpecialOffer', method: 'PUT', params: true },
  adminDeleteSpecialOffer: {
    url: '/admin/deleteSpecialOffer',
    method: 'PUT',
    params: true,
  },
  merchantLogin: { url: '/merchant/login', method: 'POST' },
  merchantProfileDetails: { url: '/merchant/get-profile', method: 'GET' },
  merchantGetAllSpecialOffers: {
    url: '/merchant/viewSpecialOffer',
    method: 'GET',
  },
  merchantAddSpecialOffer: {
    url: '/merchant/createSpecialOffer',
    method: 'POST',
  },
  merchantAddActivity: { url: '/merchant/addActivityDetails', method: 'POST' },
  merhantEditActivity: { url: '/merchant/editActivityDetails', method: 'PUT', params: true },
  merchantDeleteActivity: {
    url: '/merchant/deleteActivityDetails',
    method: 'PUT',
    params: true,
  },
  updateMerhantProfile: {
    url: '/merchant/update-profile',
    method: 'POST',
  },
  editCompanyDetails: {
    url: '/merchant/editCompany',
    method: 'POST',
  },
  registerSubMerchant: {
    url: '/merchant/subMarchentRegister',
    method: 'POST',
  },
  editSubMerchant: {
    url: '/merchant/updateProfile',
    method: 'PUT',
    params: true,
  },
  deleteSubMerchant: {
    url: '/merchant/deleteSubMarchent',
    method: 'PUT',
    params: true,
  },
  raiseIssue: {
    url: '/merchant/add-issue',
    method: 'POST',
  },
  adminAddActivity: { url: '/merchant/addActivityDetails', method: 'POST' },
  adminGetAllActivity: { url: '/merchant/viewActivityDetails', method: 'GET' },
  adminDeleteActivity: { url: '/merchant/deleteActivityDetails', method: 'PUT' },
  merchantRegister: { url: '/merchant/registration', method: 'POST' },
  addActivityAvailability: { url: '/merchant/add-availability', method: 'POST' },
  getActivityAvailabilityForADate: { url: '/merchant/view-availability', method: 'POST' },
  getActivityAvailabilityForAMonth: { url: '/merchant/view-monthwise-availability', method: 'POST' },
  editActivityAvailability: {
    url: '/merchant/edit-availability',
    method: 'PUT',
    params: true
  },
  cancelAvailabilityForDates: { url: '/merchant/date-wise-delete', method: 'PUT', params: true },
  getAllBookings: { url: '/merchant/view-booking-details', method: 'POST' },
  cancelBooking: { url: '/merchant/deleteBooking', method: 'PUT', params: true },
  getSelfInvoice: { url: '/merchant/get-invoice', method: 'POST' },
  getOrderInvoice: {
    url: '/merchant/generate-billing-pdf',
    method: 'GET',
    params: true
  },
  addCityZipCode: { url: '/user/add-zipcode', method: 'POST' },
  viewZipcodesOfCity: { url: '/user/view-zipcode', method: 'POST' },
  getAllBookingStatusTags: { url: "/merchant/get-payment-tag", method: "GET" },
};
