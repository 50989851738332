// Hooks
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { apiCallSuccess, apiCallError, getDatesBetween } from "../../../utils";
import { formatTimeToAMPM } from "../../../utils/time-functions";

// Helpers
import moment from "moment";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import { fileUpload, requestData } from "../../../services/api/apiHelpers";
// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";

// Components
// import DatePicker from "react-date-picker";
import TimePicker from "react-time-picker";
import Loader from "../../../Component/Loader";
import Select from "react-select";
import { DateRangePicker } from "react-date-range";

// import "react-date-picker/dist/DatePicker.css";
// import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import "./styles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Calendar from "../../../Calendar/Calendar";
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import CalenderModal from "./CalenderModal";
import Swal from "sweetalert2";
import "./CalenderModel.css"
// let initialValues = {
//   activityDetailsId: "",
//   activityDetailsName: "",
//   activityMaxPerson: "",
//   tourDate: [],
//   timing: [],
//   changePrice: "",
//   remeningUser: "",
// };
let initDatesValue = {
  selection1: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection1",
  },
  selection2: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection2",
  },
  selection3: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection3",
  },
  selection4: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection4",
  },
  selection5: {
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection5",
    autoFocus: false,
  },
};
let createErrors = {
  activityDetailsId: "",
  tourDate: "",
  timimg: "",
  changePrice: "",
  remeningUser: "",
};
const personType = [
  { label: "Adult", value: "adult" },
  { label: "Child/Children", value: "child/children" },
];
var numRegXp = /^[0-9]+$/;

function AddAvailability() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [dates, setdates] = useState(initDatesValue);
  const [calmodal, setCalenderModal] = useState(false);
  const [showcalk, setshowcalk] = useState(true)
  const className = showcalk ? 'dnone' : 'block';
  const [times, settimes] = useState([
    {
      time: "",
      cutoffTime: "",
    },
  ]);
 
 const [ allTimeSlot ,setallTimeSlot  ] = useState("")
  const [error, setError] = useState("");
  const [errors, seterrors] = useState(createErrors);
  const [isLoading, setisLoading] = useState(false);
  const [isFirstDateSelect, setIsFirstDateSelect] = useState(false);
  const { activities } = useSelector((state) => state.adminActivityActionSlice);
  const currentActivityData = activities?.find((item) => item?._id === id);
  const [activity, setactivity] = useState({
    activityDetailsId: currentActivityData.activityDetailsId,
    activityDetailsName: currentActivityData.activityTitle,
    activityMaxPerson: currentActivityData.tourPerson,
    tourDate: [],
    timing: [],
    changePrice: "",
    remeningUser: currentActivityData.tourPerson,
    frontrowseat:currentActivityData.frontRowSeats
  });
  const initDateArr = [
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      // key: "selection1",
    },
  ];
  const [dateRangeArr, setDateRangeArr] = useState(initDateArr);
  useEffect(() => {
    dispatch(getAllActivities());
    viewAllAvailability()
  }, []);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to beginning of the day
  const handleDateChange = (selectedDates) => {
    setdates((prev) => {
      let update = { ...prev };

      return {
        ...update,
        ...selectedDates,
      };
    });

    
    // new
    setIsFirstDateSelect(true);
    // add
    let arr = [];
    arr.push(selectedDates);
    if (isFirstDateSelect) {
      setDateRangeArr((prev) => [...prev, ...Object.values(selectedDates)]);
    } else {
      setDateRangeArr((prev) => [...Object.values(selectedDates)]);
    }
  };
  const allowOnlyPositiveInteger = (event) => {
    event.preventDefault();
    // let inputValue = event.target.value.replace(/\D/g, "");
    if (!event.target.value.match(numRegXp)) {
      // inputValue = parseInt(inputValue, 10);
      event.target.value = "";
    }
    setactivity({ ...activity, [event.target.name]: event.target.value });
  }; 

// function normalizeTime(time) {
//   return time.trim().toLowerCase();
// }
  
  const handleValidation = () => {
    const { activityDetailsId, tourDate, changePrice, remeningUser  ,} = activity;
    
  
    if (activityDetailsId === "") {
      seterrors({
        ...createErrors,
        activityDetailsId: "Activity is required",
      });

      return false;
    }
    if (dateRangeArr.length === 1) {
      seterrors({
        ...createErrors,
        tourDate: "Plaese select slot start date and end Date !!",
      });

      return false;
    } 


    if (
      times.filter((item) => item.time.length > 0 && item.cutoffTime.length > 0)
        .length === 0
    ) {
      seterrors({
        ...createErrors,
        timimg: "Atleast 1 start time and booking cutoff time is required.",
      });

      return false;
    } 

    // const times1 = times?.map(item => normalizeTime(item?.time));
    // const times2 = allTimeSlot?.map(time => normalizeTime(time)); 
    // const hasCommonTime = times1?.some(time1 => times2.includes(time1));
    // if (hasCommonTime) {
    //   seterrors({
    //     ...createErrors,
    //     timimg: "This Time slot already Exists",
    //   });
    //   toast.error("This time slot Already exist"); 
    //   return false;
    // }

    // if (changePrice === 0) {
    //   seterrors({
    //     ...createErrors,
    //     changePrice: "Change price is required.",
    //   });

    //   return false;
    // } 

    if (remeningUser === 0) {
      seterrors({
        ...createErrors,
        remeningUser: "Number of remaining seats is required.",
      });

      return false;
    }

    return true;
  };
  // Get all the dates in between as strings from the range objects of "selectedDateRanges"
  const getTourDates = (arr) => {
    let dateArrRange = arr;
    let datesArr = [];
    for (let i = 0; i < dateArrRange.length; i++) {
      let allDates = getDatesBetween(
        dateArrRange[i]?.startDate,
        dateArrRange[i]?.endDate
      );
      allDates.forEach((item) => {
        datesArr.push(item);
      });
    }
    return datesArr;
  }; 

  const viewAllAvailability = async () => {
    setisLoading(true)
    try {
        let res = await requestData(
            `/merchant/viewAlltimeslot`,
            "POST", { activityDetailsId: currentActivityData.activityDetailsId, }
        );
        if (res.status) {
          setallTimeSlot(res.data)
          setisLoading(false)
        } else {
            setisLoading(true)
        }
    } catch (error) {
    }
};



  const handleSubmit = async (event) => {
    event.preventDefault();
    if (handleValidation()) {
      if (activity.remeningUser <= activity.activityMaxPerson) {
        let selectedDateRanges = Object.entries(dates).map((item) => item[1]);
        let datesArr = [];
        for (let i = 0; i < selectedDateRanges.length; i++) {
          let allDates = getDatesBetween(
            selectedDateRanges[i].startDate,
            selectedDateRanges[i].endDate
          );
          allDates.forEach((item) => {
            datesArr.push(item);
          });
        }
        // Get only unique dates
        let uniqueDatesArr = Array.from(new Set(datesArr));

        let bodyData = {
          ...activity,
          tourDate: Array.from(new Set(getTourDates(dateRangeArr))), 
          timing: times.filter(
            (item) => item?.time.length > 0 && item?.cutoffTime.length > 0
          ),
        };
        try {

          setisLoading(true);
          let res = await JSON_API["addActivityAvailability"](bodyData);
          if (res.isSuccess) {
            seterrors(createErrors);
            if (inputRef.current) {
              inputRef.current.value = "";
            }
            settimes([
              {
                time: "",
                cutoffTime: "",
              },
            ]);
            setisLoading(false);
       
            Swal.fire({
              title: 'Availability added successfully',
              icon: 'success', // Use 'icon' instead of 'type'
              text: 'Activity Availability added successfully!',
            }).then((result) => {
              navigate(`/ManageAvailability/${id}`)
            });
          } else {
            setisLoading(false);
            apiCallError(res?.error?.message, setError);
          }
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message, setError);
        }
      } else {
        toast.error(
          `Available seats cannot be more than ${activity.activityMaxPerson} for this  activity.`
        );
        return;
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const selectDateopenmodal = () => {
    setCalenderModal(true);
  } 

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const maxDateAA = new Date(formatDate(new Date(currentActivityData.endDate)));

  return (
    <>
      {isLoading && <Loader />}

      <form className="" >
        <div
          component="div"
          className="TabsAnimation appear-done enter-done table-hover" >
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title  card_kms">

          
                  Add Availability For Product
             
              </div>


              <div className="patitle"> Product Title :  {currentActivityData.activityTitle}</div>
              {/* <div>
                <p style={{ fontSize: "14px" }}>
                  When is your activity available for customers? Add your
                  activity’s dates and times by connecting your booking system
                  or by manually entering the information for your product and
                  options.
                </p>
              </div>  */}



              <div className="row">

                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="activityDetailsId"
                      className="form-label font-weight-bold"
                    >
                      Product*
                    </label> 
                    <Select
                      name="activityDetailsId"
                      id="activityDetailsId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activities?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                        maxParticipants: item?.tourPerson,
                      }))}
                      value={{
                        value: activity.activityDetailsId,
                        label: activity.activityDetailsName,
                        maxParticipants: activity.activityMaxPerson,
                      }}
                      // isMulti
                      onChange={(val) => {
                        setactivity((prev) => {
                          return {
                            ...prev,
                            activityDetailsId: val?.value,
                            activityDetailsName: val?.label,
                            activityMaxPerson: val?.maxParticipants,
                          };
                        });
                        // setCalenderModal(true);
                      }}
                    /> 
                  </div>
                  {errors.activityDetailsId && (
                    <span className="errorMessageText text-danger">
                      {errors.activityDetailsId}
                    </span>
                  )}
                </div>  */}



                
                <div className="col-md-6">
                  <div className="mb-3">
                    {/* <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Date*
                    </label> */}
                    {/* <DatePicker
                      id="tourDate"
                      calendarIcon={null}
                      className="form-control"
                      clearIcon={null}import React from "react";
import { DateRangePicker } from "react-date-range";

export default function CalenderModal({ closeModal,today,handleDateChange,dateRangeArr,errors }) {
    return (
        <>
            <div className="modal fade show" role="dialog" tabIndex={-1}>
                <div className="modal-dialog" role="document">
                    <button
                        className="btn-close"
                        style={{
                            position: "absolute",
                            top: 15,
                            right: 15,
                            zIndex: "1",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            closeModal(false);
                        }}
                    ></button>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Choose Dates</h5>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label
                                        className="form-label font-weight-bold"
                                        htmlFor="tourDate"
                                    >
                                        Date*
                                    </label>
                                    <DateRangePicker
                                        minDate={today}
                                        months={2}
                                        direction="horizontal"
                                        initialFocusedRange={[0, 0]}
                                        onChange={(item) => {
                                            handleDateChange(item);
                                        }}
                                        ranges={dateRangeArr}
                                        
                                    />
                                </div>
                                {errors.tourDate && (
                                    <span className="errorMessageText text-danger">
                                        {errors.tourDate}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                    closeModal(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary"
                            onClick={() => {
                                closeModal(false);
                            }}
                            >
                               Done
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

                      value={activity.tourDate}
                      minDate={new Date()}
                      format="y-MM-dd"
                      // closeCalendar  // on 'select'
                      shouldCloseCalendar={({ reason }) =>
                        reason === "select" || reason === "outsideAction"
                      }
                      onChange={handleDateChange}
                    /> */}
                    {/* <DateRangePicker
                      minDate={today}
                      months={2}
                      direction="horizontal"
                      initialFocusedRange={[0, 0]}
                      onChange={(item) => {
                        // while (count < 5) {
                        //   setcount((prev) => {
                        //     let update = prev;

                        //     return update + 1;
                        //   });
                        // }
                        handleDateChange(item);
                      }}
                      rangeColors={[
                        "#3d91ff",
                        "#3ecf8e",
                        "#fed14c",
                        "#008080",
                        "#FF00FF",
                      ]}
                      disabledDates={[new Date()]}
                      ranges={[
                        dates.selection1,
                        dates.selection2,
                        dates.selection3,
                        dates.selection4,
                        dates.selection5,
                      ]}
                    /> */}
                  </div>
               
                </div>
              </div>

              <div className="dateselectbutton">

                <label

                  className="form-label"
                  style={{ fontSize: "18px", color: "black" }}
                >
                  Select Slot Starting And End Date {" "}
                  <span
                    style={{
                      color: "red",
                      fontSize: "23px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    *
                  </span>
                </label> 

                <button type="button" className=" btn btn-primary kms_yu_btn" onClick={() => setCalenderModal(true)} > Select Date  </button>
              </div>  
              <div className={className}>
                  <DateRangePicker
                    minDate={today} 
                    maxDate={maxDateAA}
                    months={2}
                    direction="horizontal"
                    initialFocusedRange={[0, 0]}
                    ranges={dateRangeArr}
                    className="selectdatecal"
                    onChange={(item) => {
                      handleDateChange(item);
                    }}
                  />

                </div>
                
              {errors.tourDate && (
                    <span className="errorMessageText text-danger">
                      {errors.tourDate}
                    </span>
                  )}

              <div className="field-add-btn">
                <button
                  className="btn btn-primary mb-3"
                  type="button"
                  onClick={() => {
                    let lastEle = times[times.length - 1];

                    if (lastEle.time.trim() === "") {
                      toast.error("Please add an activity start time");
                      return;
                    }
                    if (lastEle.cutoffTime.trim() === "") {
                      toast.error("Please add an activity booking cutoff time");
                      return;
                    }

                    settimes((prev) => [...prev, { time: "", cutoffTime: "" }]);
                  }}
                >
                  +
                </button>
              </div>

              {times.map((item, idx) => {
                return (
                  <div
                    className="d-flex"
                    key={idx}
                    style={{ alignItems: "center" }}
                  >
                    <div className="card_add_more col-md-11">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor={`time_${idx}`}
                            className="form-label"
                            style={{ fontSize: "18px", color: "black" }}
                          >
                            Start Time {" "}
                            <span
                              style={{
                                color: "red",
                                fontSize: "23px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </label>
                          <TimePicker
                            id={`time_${idx}`}
                            clockIcon={null}
                            clearIcon={null}
                            className="form-control"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            value={item.time}
                            minTime=""
                            format="hh:mm a"
                            openClockOnFocus={false}
                            onChange={(time) => {
                              let formattedTime = formatTimeToAMPM(time);

                              settimes((state) => {
                                state[idx].time = formattedTime;

                                return JSON.parse(JSON.stringify(state));
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor={`cutoffTime_${idx}`}
                            className="form-label"
                            style={{ fontSize: "18px", color: "black" }}
                          >
                            Booking Cutoff Time {" "}
                            <span
                              style={{
                                color: "red",
                                fontSize: "23px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </label>
                          <div>
                            <p style={{ fontSize: "13px" }}>
                              The cut-off time is the very latest you accept new
                              bookings before the activity starts.Since many
                              customers book right before they want to start
                              their activity, having a cut-off time of 30
                              minutes or less can lead to 50% more bookings than
                              longer cut-off times. How far in advance do you
                              stop accepting new bookings before the activity
                              starts?
                            </p>
                          </div>
                          <TimePicker
                            id={`cutoffTime_${idx}`}
                            clockIcon={null}
                            clearIcon={null}
                            className="form-control"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            value={item.cutoffTime}
                            format="hh:mm a"
                            openClockOnFocus={false}
                            onChange={(time) => {
                              let formattedTime = formatTimeToAMPM(time);
                              if (
                                moment(times[idx].time, "h:mm A").isBefore(
                                  moment(formattedTime, "h:mm A")
                                )
                              ) {
                                toast.error(
                                  "Booking cutoff time should not be greater than start time"
                                );
                                return;
                              }
                              settimes((state) => {
                                state[idx].cutoffTime = formattedTime;

                                return JSON.parse(JSON.stringify(state));
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {idx !== 0 && (
                      <button
                        type="button"
                        className="btn btn-danger ml-auto d-table"
                        onClick={() =>
                          settimes((prev) => {
                            let updated = [...prev];
                            updated.splice(idx, 1);
                            return updated;
                          })
                        }
                      >
                        -
                      </button>
                    )}
                  </div>
                );
              })}

              {errors.timimg && (
                <span className="errorMessageText text-danger">
                  {errors.timimg}
                </span>
              )}

              <div className="row">
                {/* <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="changePrice"
                      className="form-label font-weight-bold"
                    >
                      Price To Set*
                    </label>
                    <input
                      name="changePrice"
                      id="changePrice"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={activity.changePrice}
                      onChange={allowOnlyPositiveInteger}
                    />
                  </div>
                  {errors.changePrice && (
                    <span className="errorMessageText text-danger">
                      {errors.changePrice}
                    </span>
                  )}
                </div> */}
                <div className="col-md-6 d-none">
                  <div className="mb-3">
                    <label
                      htmlFor="remeningUser"
                      className="form-label font-weight-bold"
                    >
                      Remaining Seats*
                    </label>
                    <input
                      name="remeningUser"
                      id="remeningUser"
                      type="text"
                      className="form-control"
                      autoComplete="false"
                      value={activity.remeningUser}
                      onChange={allowOnlyPositiveInteger}
                    />
                  </div>
                  {errors.remeningUser && (
                    <span className="errorMessageText text-danger">
                      {errors.remeningUser}
                    </span>
                  )}
                </div>
              </div>




              <button
                className="mt-2 btn btn-primary ml-auto  "
                style={{display:isLoading?"none":"block"}}
                type="submit"
            


                 
                onClick={(e) => handleSubmit(e) }
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {calmodal && (
          <CalenderModal
            closeModal={setCalenderModal}
            today={today}
            maxDate={maxDateAA}
            handleDateChange={(item) => handleDateChange(item)}
            dateRangeArr={dateRangeArr}
            errors={errors}
            cssmodal={setshowcalk}
          />
        )}
      </form>
      
    </>
  );
}

export default AddAvailability;
