import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// Helpers
import { ApiHelperFunction } from '../../services/api/apiHelpers';

let initialState = {
  status: 'idle',
  faqs: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getQuestions = createAsyncThunk('get_account_details', async () => {
  try{
  let response = await ApiHelperFunction({
    urlPath: '/merchant/view-FAQ',
    method: 'GET',
  });

  if (response.status === 200) {
    return response.data;
  } else {
    toast.error("Can't get data. Something went wrong");
  }
}catch(error){
}
});

export const FactsSlice = createSlice({
  name: 'facts_actions',
  initialState,
  reducers: {
    clearState: state => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getQuestions.pending, state => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getQuestions.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
        state.faqs = payload.data;
      })
      .addCase(getQuestions.rejected, state => {
        state.status = 'failed';
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { clearState } = FactsSlice.actions;

export default FactsSlice.reducer;
