import  { useEffect, useState } from "react";
import { requestData } from "../services/api/apiHelpers";

function useCountries() {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading,setLoading] = useState(false)

  const fetchCountries = async () => {
    let result = await requestData("/user/viewCountry", "GET");
    if (result && result.status) {
      setCountries(result.data);
    }
  };
  const fetchCities = async (countryId) => {
    try{
    setLoading(true)
    let result = await requestData(
      `/user/city-against-country/${countryId}`,
      "GET"
    );
    if (result && result.status) {
      setCities(result.data);
    }
    setLoading(false)
  }catch(error){
            }
  };
  useEffect(() => {
    fetchCountries();
  }, []);
  return { countries, cities,fetchCities ,loading};
}

export default useCountries;
