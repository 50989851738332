// Hooks
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiCallError } from "../../../utils/common-utils";
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";
import { requestData } from "../../../services/api/apiHelpers";
import useActivitiesMethods from "../../../Hooks/useActivitiesMethods";
import SelectInput from "../../../Component/SelectInput";
import useCountries from "../../../Hooks/useCountries";
import Swal from "sweetalert2";

function ManageTourModule() {
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [modalToggleView] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const { fetchTourModules, tourModules } = useActivitiesMethods();
  const { cities, fetchCities, loading } = useCountries();
  const [cityId, setCityId] = useState("");
  const { profile_data } = useSelector(
    (state) => state.profileSlice
  );



  useEffect(() => {
    if (profile_data?.countryId) fetchCities(profile_data?.countryId);
  }, [profile_data?.countryId]);

  useEffect(() => {
    setisLoading(loading);
  }, [loading]);

  const handleDelete = useCallback(async (id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setisLoading(true);
          let res = await requestData(`/merchant/delete-tour-module/${id}`, "PUT");

          if (res.status) {
            fetchTourModules(cityId)
            setModal(false);
            setisLoading(false);
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
          } else {
            setisLoading(false);
            apiCallError(res?.error?.message,);
          }
        } catch (error) {
          setisLoading(false);
          apiCallError(error.message,);
        }
      }
    });
    return;

  }, [ ]);




  const filterRows = () => {
    if (searchText !== "") {
      return tourModules?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return tourModules;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 270,
        },
        {
          label: "Module Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Image",
          field: "image",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows:
        filterRows()?.length > 0
          ? filterRows()?.map((item, idx) => {
            return {
              sl: idx + 1,
              name: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div
                      className="widget-content-left flex2"
                      style={{ textAlign: "center" }}
                    >
                      {item?.name.charAt(0).toUpperCase() +
                        item?.name.slice(1)}
                    </div>
                  </div>
                </div>
              ),
              image: (
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div className="widget-heading" style={{ opacity: 1 }}>
                        <img
                          src={item?.image}
                          alt="module_image"
                          style={{
                            height: "70px",
                            width: "120px",
                            borderRadius: "3px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ),
              action: (
                <div>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => {
                      navigate(`/edit-tour-module/${item?._id}`);
                    }}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>

                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={(e) => handleDelete(item?._id)}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                </div>
              ),
            };
          })
          : [],
    };
  }, [searchText, tourModules, filterRows, navigate, handleDelete]);


  return (
    <>
      {isLoading && <Loader />}
      <div className="card my-2">
        <div className="card-body ">
          <div className="row">
            <SelectInput
              className="col-md-6 mb-3"
              label="Select City To See The Tour Module"
              options={cities}
              labelKey="cityName"
              valueKey="_id"
              value={cityId}
              onChange={(val) => {
                setCityId(val._id);
                fetchTourModules(val._id);
              }}
            />
          </div>
        </div>
      </div>


      <Table
        striped
        tableTitle="All Tour Modules"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        // renderModalView={renderModalView}
        onSearch={(val) => setsearchText(val)}
        tabflag={true}
      />

    </>
  );
}

export default ManageTourModule;
