// Hooks
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Utils
import { apiCallSuccess, apiCallError } from "../../../utils/common-utils";

// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllSpecialOffers } from "../../../redux/merchantSlice/specialOfferSlice";

// Components
import Table from "../../../Component/Table";
import Loader from "../../../Component/Loader";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-datetime/css/react-datetime.css";

export default function ManageSpecialOffer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setsearchText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalToggleView, setmodalToggleView] = useState(null);
  const [deleteId, setdeleteId] = useState("");
  const [offerTab, setOfferTab] = useState(false);
  // Redux stores
  const { special_offers } = useSelector((state) => state.specialOfferSlice);

  const { flag } = useSelector((state) => state.controlTable);


  useEffect(() => {
    dispatch(getAllSpecialOffers());
    // dispatch(getAllActivityTypes());
    // dispatch(getAllCountries());
  }, [dispatch]);

  const openModal = () => {
    setModal(true);
  };

  const handleSearch = (event) => {
    setsearchText(event.target.value);
  };

  const deleteOffer = async (id) => {
    try {
      setisLoading(true);
      let res = await JSON_API["adminDeleteSpecialOffer"]({ id: id });

      if (res.isSuccess) {
        dispatch(getAllSpecialOffers());
        setModal(false);
        apiCallSuccess(res.data);
      } else {
        apiCallError(res?.error?.message,);
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      apiCallError(error.message,);
    }
  };

  const filterData = useMemo(() => {
    if (searchText !== "") {
      return special_offers.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return special_offers;
  }, [special_offers, searchText]);

  const tableData = {
    columns: [
      {
        label: "SL",
        field: "sl",
        sort: "asc",
        width: 50,
      },
      {
        label: "Offer Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Discount Percentage",
        field: "discount",
        sort: "asc",
        width: 200,
      },
      //   {
      //     lable: "Full Details",
      //     field: "details",
      //     sort: "asc",
      //     width: 150,
      //   },
      {
        label: "Approval Status",
        field: "approval",
        sort: "asc",
        width: 80,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:
      filterData?.length > 0
        ? filterData.map((item, idx) => {
            return {
              sl: idx + 1,
              name: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 0.75, textAlign: "center" }}
                      >
                        {item?.specialOfferName}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              discount: (
                <div className="widget-content p-0" key={idx}>
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2">
                      <div
                        className="widget-heading"
                        style={{ opacity: 0.75, textAlign: "center" }}
                      >
                        {item?.discountPercentage + "%"}
                      </div>
                      {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                    </div>
                  </div>
                </div>
              ),
              approval: item?.isApproval ? (
                <div style={{ textAlign: "center" }}>
                  <i
                    className="fa-solid fa-check"
                    style={{ color: "#46b616" }}
                  />{" "}
                  <div style={{ color: "#46b616" }}>Approved</div>
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <i
                    className="fa-solid fa-xmark"
                    style={{ color: "#cf2507" }}
                  />
                  <div style={{ color: "#cf2507" }}>Not Approved</div>
                </div>
              ),
              action: (
                <div key={idx}>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-danger"
                    onClick={() => {
                      openModal();
                      setmodalToggleView(0);
                      setdeleteId(special_offers[idx]._id);
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                  <button
                    className="me-2 btn-icon btn-icon-only btn btn-outline-success"
                    onClick={() => navigate(`/edit-special-offer/${item._id}`)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                </div>
              ),
            };
          })
        : [],
  };

  useEffect(() => {
    setOfferTab(true);
    return () => {
      setOfferTab(false);
    };
  });

  const renderModalView = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            {isLoading && <Loader />}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete subadmin</h5>
              </div>
              <div className="modal-body">
                <div className="deleteModalContainer p-5">
                  <i className="ri-close-circle-line deleteIcon"></i>
                  <h3>Are You Sure?</h3>
                  <span>Do You Want To Delete This Information?</span>
                  <div className="deleteModalNote">
                    <span>*Note : This data will be permanently deleted</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    deleteOffer(deleteId);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Table
        striped
        tableTitle="All Special Offers"
        data={tableData}
        modal={modal}
        setModal={setModal}
        modalToggleView={modalToggleView}
        renderModalView={renderModalView}
        onSearch={handleSearch}
        tabflag={flag}
        offerTab={offerTab}
      />
    </>
  );
}
