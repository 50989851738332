import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "", 
};

export const YearGetSlice = createSlice({
  name: "year",
  initialState,
  reducers: {
    getValue: (state, action) => {
      state.value = action.payload; 
    },
  },
});

export const { getValue } = YearGetSlice.actions;

export default YearGetSlice.reducer;

