import React, { useEffect, useState } from "react";

// Components
import { MDBDataTable } from "mdbreact";
import Modal from "./Modal/Modal";
import "./styles.css";
import { useDispatch } from "react-redux";
import { getAllActivities } from "../redux/adminSlice/activitySlice";
import { getAllSpecialOffers } from "../redux/merchantSlice/specialOfferSlice";
import { getAllReviewAndRating } from "../redux/merchantSlice/reviewRatingSlice";
import { getValue } from "../redux/merchantSlice/yearSlice"

export default function Table(props) {
  const {
    striped,
    tableTitle,
    data,
    modal,
    setModal,
    modalToggleView,
    renderModalView,
    paging = true,
    onSearch,
    
    tabflag,
    offerTab,
    adminTotalGetPrice,
    marchentTotalGetPrice,
    invoice,
  } = props;
  const [bgColorBookable, setBgColorBookable] = useState(false);
  const [bgColorExpired, setBgColorExpired] = useState(false);
  const [activeOnlyColor, setActiveOnlyColor] = useState(false);
  const [unActiveOnlyColor, setUnActiveOnlyColor] = useState(false);
  const [allColor, setAllColor] = useState(false);
  const dispatch = useDispatch();


  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: 80 },
    (_, index) => currentYear - 40 + index
  );

  const [selectedYear, setSelectedYear] = useState("");

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    dispatch(getValue(e.target.value))
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSelectedYear(currentYear.toString());
    dispatch(getValue(currentYear.toString()))
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllActivities());
    dispatch(getAllSpecialOffers());
    dispatch(getAllReviewAndRating());
  }, [dispatch]);



  return (
    <>
      <div className="main-card mb-3 card table-hover">
        <h4 className="text-primary pb-4 m-4" style={{ fontWeight: 500 }}>
          {tableTitle}
        </h4>
        <div
          className="tab"
          style={{ display: tabflag ? "none" : "block", width: "344px" }}
        >
          <button
            style={{
              backgroundColor: bgColorBookable ? "#72a4e1" : "",
              color: bgColorBookable ? "#fff" : "",
            }}
            className="tablinks"
            onClick={() => {
              dispatch(getAllActivities("bookable_products"));
              setBgColorBookable(true);
              setBgColorExpired(false);
            }}
          >
            Bookable Products
          </button>
          <button
            style={{
              backgroundColor: bgColorExpired ? "#72a4e1" : "",
              color: bgColorExpired ? "#fff" : "",
            }}
            className="tablinks"
            onClick={() => {
              dispatch(getAllActivities("expire_product"));
              setBgColorExpired(true);
              setBgColorBookable(false);
            }}
          >
            Expired Products
          </button>
        </div>
        <div
          className="tab"
          style={{ display: !offerTab ? "none" : "block", width: "344px" }}
        >
          <button
            style={{
              backgroundColor: activeOnlyColor ? "#72a4e1" : "",
              color: activeOnlyColor ? "#fff000" : "",
            }}
            onClick={() => {
              dispatch(getAllSpecialOffers("active"));
              setActiveOnlyColor(true);
              setUnActiveOnlyColor(false);
              setAllColor(false);
            }}
          >
            Active only
          </button>
          <button
            style={{
              backgroundColor: unActiveOnlyColor ? "#72a4e1" : "",
              color: unActiveOnlyColor ? "#fff" : "",
            }}
            onClick={() => {
              dispatch(getAllSpecialOffers("unactive"));
              setUnActiveOnlyColor(true);
              setActiveOnlyColor(false);
              setAllColor(false);
            }}
          >
            UnActive only
          </button>
          <button
            style={{
              backgroundColor: allColor ? "#72a4e1" : "",
              color: allColor ? "#fff" : "",
              width: "87px",
            }}
            onClick={() => {
              dispatch(getAllSpecialOffers());
              setAllColor(true);
              setUnActiveOnlyColor(false);
              setActiveOnlyColor(false);
            }}
          >
            All
          </button>
        </div>
        {/* //.......finance */}
        {adminTotalGetPrice && adminTotalGetPrice > 0 ? (
          <>
            <h4 className=" pb-4 m-4" style={{ fontWeight: 500 }}>
              <span className="text-primary mr-2"
                style={{
                  fontWeight: "28px",
                  // backgroundColor: "brown",
                  // color: "white",
                }}
              >
                Merchant GetPrice:
              </span>{" "}{" "}
              {Math.ceil(marchentTotalGetPrice)}
            </h4>

            {/* <h4 className="text-primary pb-4 m-4" style={{ fontWeight: 500 }}>
              <span
                style={{
                  fontWeight: "28px",
                  backgroundColor: "brown",
                  color: "white",
                }}
              >
                Marchant GetPrice:
              </span>
              {marchentTotalGetPrice.toFixed(2)}
            </h4> */}
          </>
         ):""} 
        {invoice ? (
          <div className="col-md-2">
            <label htmlFor="yearDropdown">Select a Year:</label>
            <select className="form-select" aria-label="Default select example"
              id="yearDropdown"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="">Select</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0px 10px",
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={onSearch}
            style={{ width: "40%" }}
          />
        </div>
        {striped ? (
          <MDBDataTable striped data={data} searching={false} paging={paging} />
        ) : (
          <MDBDataTable data={data} />
        )}
      </div>
      {modal && (
        <Modal
          modalToggleView={modalToggleView}
          renderModalView={renderModalView}
          closeModal={setModal}
        />
      )}
    </>
  );
}
